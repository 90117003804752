a.top {
    display:none;
    position: fixed;
    bottom: 10px;      
    right: 10px; 
    width: 50px;
    height: 50px;
    padding: 12px 10px;
    background: $color-main;
    border-radius: 100px;
    z-index: 2;
    svg {
      width: 100%;
      height: auto;
    }
}
