.about {
    background-color: rgba(60, 61, 65, 0.3);
    backdrop-filter: blur(5px);
    display: grid;
    grid-template-columns: minmax(400px,25%) auto;
    column-gap: 140px;
    padding-bottom: 180px;
    padding-top: 30px;
    margin-top: -200px;
    z-index: 1;
    position: relative;
    @include mq($until: xl){
        column-gap: 80px;
    }
    @include mq($until: md){
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        margin-top: -100px;
    }
    @include mq($until: lg){
        padding-bottom: 80px;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(60, 61, 65, 0.8);
        mix-blend-mode: multiply;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    &__content {
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        row-gap: 80px;
        @include mq($until: md){
            flex-direction: column-reverse;
            text-align: center;
        }
        h2 {
            color: $color-main;
            font-size: rem(31px);
            font-weight: 800;
            margin-bottom: 50px;
            @include mq($until: md){
                color: #fff;
            }
        }
        h3 {
            color: #fff;
            font-size: rem(23px);
            font-weight: 700;
            margin-bottom: 40px;
            @include mq($until: md){
                font-size: rem(31px);
                font-weight: 800;    
            }
        }
        p {
            color: #fff;
            font-size: rem(17px);
            font-weight: 500;
            line-height: rem(30px);
        }
        svg {
            position: absolute;
            bottom: 100px;
            left: -120px;
            width: 350px;
            height: auto;
        }
    }

    &__investments {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 60px;
        @include mq($until: xl){
            display: flex;
            flex-direction: column;
            row-gap: 40px;
        }
        p {
            color: #fff;
            font-size: rem(17px);
            font-weight: 500;
            line-height: rem(30px);
            img {
                max-height: 50px;
                width: auto;
                margin-bottom: 20px;
            }
        }
        .txtimg {
            transition: ease 0.3s all;
            padding: 20px;
            border: 1px solid transparent;
            border-radius: 5px;
            row-gap: 40px;
            height: 100%;
            @include mq($until: xl){
                display: grid;
                grid-template-columns: 33% auto;
                column-gap: 40px;
                align-items: start;
                height: auto;
            }
            @include mq($until: md){
                align-items: center;
                column-gap: 30px;
            } 
            @include mq($until: xs){
                grid-template-columns: 1fr;
                row-gap: 30px;
                text-align: center;
            }
            &:hover {
                background-color: rgba(255, 255, 255, 0.05);
                border-color: rgba(255, 255, 255, 0.28);
            }
        }
    }

}
