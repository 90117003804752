.company {
    .hero {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        h1 {
            color: #fff;
            font-weight: 800;
            text-align: center;
            font-size: rem(43px);
            @include mq($until: md){
                font-size: rem(36px);
            }
            @include mq($until: xs){
                font-size: rem(30px);
            }
        }
        h4 {
            color: $color-main;
            font-weight: 800;
            text-align: center;
            font-size: rem(26px);
            margin-top: 0;
        }
        .row {
            margin-top: 100px;
            row-gap: 60px;
        }
        .col-right, .col-left {
            margin-bottom: 0;
        }
        p {
            color: #fff;
            font-weight: 800;
            text-align: center;
            font-size: rem(26px);
            @include mq($until: xl){
                font-size: rem(24px);
            }
            @include mq($until: lg){
                font-size: rem(22px);
            }
            @include mq($until: xs){
                font-size: rem(18px);
            }
            strong {
                color: $color-main;
                font-weight: 800;
                font-size: rem(65px);
                @include mq($until: xl){
                    font-size: rem(55px);
                }
                @include mq($until: lg){
                    font-size: rem(45px);
                }
                @include mq($until: xs){
                    font-size: rem(35px);
                }    
            }
        }
    }
    .fromhere {
        padding-top: 140px;
        padding-bottom: 140px;
        display: grid;
        grid-template-columns: minmax(400px, 30%) auto;
        column-gap: 100px;
        align-items: center;
        @include mq($until: md){
            grid-template-columns: 1fr;
            row-gap: 80px;
            padding-top: 50px;
            padding-bottom: 50px;    
        }    
        h2 {
            font-size: rem(43px);
            font-weight: 800;
            @include mq($until: md){
                font-size: rem(26px);
            }
        }
        h3 {
            font-size: rem(23px);
            font-weight: 800;
            color: $color-main;
        } 

    }

    .team {
        display: grid;
        grid-template-columns: minmax(400px, 28.3%) auto;
        overflow: hidden;
        align-items: start;
        margin-top: 140px;
        margin-bottom: 140px;
        @include mq($until: 1400px){
            grid-template-columns: 1fr 1fr;
            margin-top: 0;
        }
        @include mq($until: md){
            grid-template-columns: 1fr;
            margin-top: 80px;
        }

        .side {
            margin-top: 130px;
            @include mq($until: 1400px){
                margin-top: 0;
            }
            h4 {
                color: #fff;
                font-size: rem(24px);
                font-weight: 800;
                display: block;
                text-align: center;
                padding: 40px 0;
                margin: 0;
            }
            .icons {
                background-color: #464B5D;
                display: grid;
                grid-template-columns: 1fr;
                padding-left: 40px;
                padding-right: 40px;
                padding-top: 40px;
                padding-bottom: 80px;
                row-gap: 40px;
                &-set {
                    row-gap: 80px;
                    margin: 0;
                    @include mq($until: md){
                        grid-template-columns: 1fr 1fr;
                        column-gap: 30px;
                    }
                    @include mq($until: xs){
                        grid-template-columns: 1fr;
                    }
                    &__item-content {
                        display: none;
                    }
                }
            }
        }
        .right {
            .content {
                display: grid;
                grid-template-columns: auto minmax(400px, 28.3%);
                position: relative;
                @include mq($until: 1400px){
                    grid-template-columns: 1fr;
                    img {
                        display: none;
                    }
                }
                h2 {
                    font-weight: 800;
                    font-size: rem(26px);
                    color: #000;    
                    position: relative;
                    padding-top: 40px;
                    padding-bottom: 40px;
                    margin-bottom: 40px;
                }
                p {
                    padding-right: 80px;
                    @include mq($until: md){
                        padding-right: 50px;
                    }
                    @include mq($until: sm){
                        padding-right: 30px;
                    }
                    @include mq($until: xs){
                        padding-right: 20px;
                    }
                }
                & > div {
                    padding-bottom: 60px;
                }
            }
            img {
                padding-bottom: 60px;
                @include mq($until: md){
                    padding-bottom: 0;
                }
            }
            .image {
                padding-right: 12vw;
                @include mq($until: 1400px){
                    padding-right: 0;
                    margin-top: 20px;
                }

            }
        }
        .animate-border {
            hr {
                background-color: #53575E;
                position: absolute;
                width: 1px;
                height: 0%;
                right: 8vw;
                bottom: 0;
                border: unset;
                margin:0;
                padding:0;
                transition: ease 2s all;
                @include mq($until: xl){
                    display: none;
                }
            }
            &.l hr.h {
                left: 0;
                right: unset;
                width: 0;
                height: 1px;
            }
            &.l.anmt hr.h {
                width: 100%;
            }
            &.b {
                position: relative;
            }
            &.b hr.v {
                right: -4vw;
                left: unset;
                width: 1px;
                height: 0;
                z-index: -1;
            }
            &.b.anmt hr.v {
                height: 100%;
            }
        }
    }


    .localization {
        @include mq($until: md){
            min-height: 50vh;
        }
        &__content {
            bottom: 100px;
            @include mq($until: md){
                bottom: 40px;
            }
        }
        &:after {
            content: '';
            height: 400px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, transparent 100%);
        }
    }
    .features {
        h3 {
            font-size: rem(24px);
            font-weight: 700;
            margin-bottom: 40px;
        }
        p {
            font-size: 17px;
            color: #464B5D;
            strong {
                display: inline;
                font-size: 17px;
                color: #464B5D;
            }
        }
        a {
            margin-top: 20px;
            display: inline-block;
        }
    }
    .timeline {
        padding-top: 100px;
        padding-bottom: 140px;
        @include mq($until: md){
            padding-top: 40px;
            padding-bottom: 40px;
        }
        .year {
            display: block;
            text-align: center;
            font-weight: 800;
            font-size: rem(34px);
            color: #000;
            margin: 40px 0;
            transition: ease 0.3s all;
            &.visible {
                color: $color-main;
                @include mq($until: md){
                    color: #000;
                }
            }
            @include mq($until: md){
                &:not(:first-of-type) {
                    margin-top: 80px;
                }
            }
        }
        .event {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            position: relative;
            @include mq($until: md){
                grid-template-columns: 1fr;
            }
            &.visible {
                &:after {
                    background: $color-main;
                }
            }
            &:after {
                content: '';
                width: 1px;
                height: 100%;
                position: absolute;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
                transition: ease 0.3s all;
                background: #000;
                @include mq($until: md){
                    display: none;
                }
            }
            & > div:nth-child(1) {
                padding-right: 100px;
                @include mq($until: md){
                    padding-right: 0;
                }    
            }
            & > div:nth-child(2) {
                padding-left: 100px;
                @include mq($until: md){
                    padding-left: 0;
                }    
            }
            @include mq($until: md){
                &.img-right {
                    .content {
                        order: 2;
                    }
                }
            }
            .content {
                p {
                    font-size: rem(26px);
                    font-weight: 700;
                    line-height: rem(42px);
                    margin-bottom: 0;
                    @include mq($until: xl){
                        font-size: rem(20px);
                        line-height: rem(28px);
                    }
                    @include mq($until: md){
                        text-align: center;
                    }
                }
                img {
                    margin-bottom: 40px;
                    @include mq($until: md){
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
            .image {
                position: relative;
                text-align: center;
                svg {
                    width: 100%;
                    height: auto;
                }
                &.no-mask {
                    margin-bottom: 30px;
                }
            }
        }
    }
    .footer-content {
        position: relative;
    }
}
