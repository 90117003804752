.project {
    &-top {
        display: grid;
        grid-template-columns: 700px minmax(0, 1fr);
        min-height: 90vh;
        @include mq($until: 1400px){
            grid-template-columns: 50% 50%;
        }
        @include mq($until: md){
            grid-template-columns: 100%;
        }
        .content {
            padding-right: 80px;
            padding-top: 40px;
            padding-bottom: 40px;
            @include mq($until: md){
                padding-top: 80px;
                padding-right: 50px;
                order: 1;
            }
            @include mq($until: sm){
                padding-right: 30px;
            }
            @include mq($until: xs){
                padding-right: 20px;
            }
            h1 {
                font-weight: 800;
                font-size: rem(26px);
                color: #000;
            }
            p {
                font-size: rem(16px);
                strong {
                    color: $color-main;
                    em {
                        color: $color-extra;
                        font-style: normal;
                    }
                }
            }
        }
        .photos {
            background-color: $color-extra;
            position: relative;
            @include mq($until: md){
                aspect-ratio: 4/3;
            }
            .logo {
                position: absolute;
                top: 40px;
                left: 40px;
                max-width: 200px;
                z-index: 2;
            }
            .slider {
                position: relative;
                z-index: 1;
                height: 100%;
                &:hover {
                    .slick-arrow {
                        opacity: 1;
                    }
                }
                .project__slide:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: linear-gradient(135deg, rgba(0, 0, 0, 0.5) 0%, transparent 50%);
                    width: 100%;
                    height: 100%;
                }
                .project__photo {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-position: center;
                    background-size: cover;
                    top: 0;
                    left: 0;
                }
                .slide-photo {
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                }
                .slick-slider, .slick-list, .slick-track {
                    height: 100%;
                }
                .slick-slide * {
                    font-size: 0;
                }
                .slick-arrow {
                    left: 150px;
                    bottom: 80px;
                    top: unset;
                    z-index: 9;
                    transform: translate(0, 0);
                    position: absolute;
                    background: rgba(255, 255, 255, 0.5);
                    fill: #1d1c1c;
                    stroke: #1d1c1c;
                    backdrop-filter: blur(5px);
                    width: 50px;
                    height: 50px;
                    border-radius: 100%;
                    padding: 15px;
                    cursor: pointer;
                    transition: ease 0.3s;
                    opacity: 0;
                    line {stroke-width: 2;}
                    @include mq($until: md){
                        display: none!important;
                    }
                    &:hover {
                        background-color: $color-main;
                        fill: #fff;
                        stroke: #fff;
                    }
                    @include mq($until: md){
                        left: 110px;
                        bottom: -80px;
                    }
                    @include mq($until: sm){
                        left: 90px;
                    }
                    @include mq($until: xs){
                        left: 80px;
                    }
                    &.slick-prev {
                        transform: translate(0, 0) rotate(180deg);
                        left: 80px;
                        @include mq($until: md){
                            left: 50px;
                        }    
                        @include mq($until: sm){
                            left: 30px;
                        }    
                        @include mq($until: xs){
                            left: 20px;
                        }    
                    }
                }
            }
        }
    }
    &-website {
        display: grid;
        grid-template-columns: 700px minmax(0, 1fr);
        @include mq($until: 1400px){
            grid-template-columns: 50% 50%;
        }
        @include mq($until: md){
            grid-template-columns: auto 1fr;
        }
        @include mq($until: xs){
            grid-template-columns: 1fr;
            padding: 0 20px;
        }
        a {
            background-color: $color-extra;
            padding-top: 20px;
            padding-bottom: 20px;
            color: #fff;
            @include mq($until: md){
                padding-right: 50px;
            }
            @include mq($until: sm){
                text-align: center;
                padding-right: 30px;
            }
            @include mq($until: xs){
                padding-right: 20px;
            }
            span {
                color: #fff;
                @include mq($until: sm){
                    display: none;
                }
            }
        }
    }
    &-features {
        display: grid;
        grid-template-columns: minmax(400px, 28.3%) auto;
        overflow: hidden;
        align-items: start;
        margin-top: 140px;
        @include mq($until: 1400px){
            grid-template-columns: 1fr 1fr;
            margin-top: 0;
        }
        @include mq($until: md){
            grid-template-columns: 1fr;
            margin-top: 80px;
        }

        .side {
            margin-top: 130px;
            @include mq($until: 1400px){
                margin-top: 0;
            }
            .icons {
                background-color: #464B5D;
                display: grid;
                grid-template-columns: 1fr 1fr;
                padding-right: 60px;
                padding-top: 40px;
                padding-bottom: 40px;
                row-gap: 30px;
                column-gap: 30px;
                @include mq($until: md){
                    grid-template-columns: 1fr 1fr 1fr;
                    padding-right: 50px;
                }
                @include mq($until: sm){
                    grid-template-columns: 1fr 1fr;
                    padding-right: 30px;
                }
                @include mq($until: xs){
                    grid-template-columns: 1fr;
                    padding-right: 20px;
                }
        
                &-single {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    text-align: center;
                    align-items: center
                }
                img {
                    max-width: 80px;
                }
                p {
                    color: #fff;
                    font-size: rem(17px);
                }
            }
            a {
                background-color: $color-main;
                color: #fff;
                display: block;
                padding-top: 20px;
                padding-bottom: 20px;
                padding-right: 60px;
                @include mq($until: xs){
                    display: none;
                }    
            }
            .logo {
                padding-right: 60px;
                padding-top: 40px;
                padding-bottom: 40px;
                @include mq($until: 1400px){
                    text-align: center;
                }
            }
        }
        .right {
            .content {
                display: grid;
                grid-template-columns: auto minmax(400px, 28.3%);
                position: relative;
                @include mq($until: 1400px){
                    grid-template-columns: 1fr;
                    img {
                        display: none;
                    }
                }
                h2 {
                    font-weight: 800;
                    font-size: rem(26px);
                    color: #000;    
                    position: relative;
                    padding-top: 40px;
                    padding-bottom: 40px;
                    margin-bottom: 40px;
                }
                p {
                    padding-right: 80px;
                    @include mq($until: md){
                        padding-right: 50px;
                    }
                    @include mq($until: sm){
                        padding-right: 30px;
                    }
                    @include mq($until: xs){
                        padding-right: 20px;
                    }
                }
                & > div {
                    padding-bottom: 60px;
                }
            }
            img {
                padding-bottom: 60px;
                @include mq($until: md){
                    padding-bottom: 0;
                }
            }
            .image {
                padding-right: 12vw;
                @include mq($until: 1400px){
                    padding-right: 0;
                    margin-top: 20px;
                }

            }
        }
        .animate-border {
            hr {
                background-color: #53575E;
                position: absolute;
                width: 1px;
                height: 0%;
                right: 8vw;
                bottom: 0;
                border: unset;
                margin:0;
                padding:0;
                transition: ease 2s all;
                @include mq($until: xl){
                    display: none;
                }
            }
            &.l hr.h {
                left: 0;
                right: unset;
                width: 0;
                height: 1px;
            }
            &.l.anmt hr.h {
                width: 100%;
            }
            &.b {
                position: relative;
            }
            &.b hr.v {
                right: -4vw;
                left: unset;
                width: 1px;
                height: 0;
                z-index: -1;
            }
            &.b.anmt hr.v {
                height: 100%;
            }
        }
    }

    &-localization {
        padding-top: 140px;
        @include mq($until: 1400px){
            padding-top: 80px;
        }
        .content {
            display: grid;
            grid-template-columns: 700px minmax(0, 1fr);
            @include mq($until: 1400px){
                grid-template-columns: 1fr;
                gap: 40px;
            }

            h2 {
                padding-right: 80px;
                margin-bottom: 0;
                position: relative;
                font-weight: 800;
                font-size: rem(26px);
                color: #000;

            }
            & > div {
                padding-bottom: 100px;
            }
            p {
                font-size: rem(16px);
                @include mq($until: 1400px){
                    padding-left: 100px;
                }
                @include mq($until: lg){
                    padding-left: 50px;
                }
                @include mq($until: sm){
                    padding-left: 30px;
                }
                @include mq($until: xs){
                    padding-left: 20px;
                }
            }
        }
        .map {
            height: 70vh;
        }
        .animate-border {
            hr {
                background-color: #53575E;
                position: absolute;
                width: 1px;
                height: 0%;
                right: 8vw;
                bottom: 0;
                border: unset;
                margin:0;
                padding:0;
                transition: ease 2s all;
                @include mq($until: xl){
                    display: none;
                }
            }
            &.l hr {
                left: 50px;
                right: unset;
            }
            &.anmt hr {
                height: 100%;
            }
        }
    }
}
