.localization {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 100px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    @include mq($until: md){
        min-height: 70vh;
        background-position: 88% center;
    }
    @include mq($until: 500px){
        background-position: 80% center;
    }
    &__content {
        position: absolute;
        left: 0;
        bottom: 240px;
        z-index: 1;
        max-width: 800px;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        align-items: flex-start;
        @include mq($until: md){
            max-width: 100%;
            margin: 0;
            padding: 0 40px;
            text-align: center;
            align-items: center;
            bottom: 140px;
        }
        h2 {
            color: #fff;
            margin-bottom: 0;
            font-size: rem(42px);
            font-weight: 800;
            text-shadow: 0 3px 99px #000;
            @include mq($until: md){
                font-size: rem(32px);
            }
        }
        h3 {
            color: #fff;
            margin-top: 0;
            margin-bottom: 0;
            font-size: rem(24px);
            font-weight: 700;
            text-shadow: 0 3px 99px #000;
        }
        p {
            color: #fff;
            text-shadow: 0 3px 99px #000;
            &:first-of-type {
                margin-top: 20px;
            }
        }
        a {
            display: block;
            align-self: unset;
            margin-top: 20px;
        }
        &--center {
            text-align: center;
            align-items: center;
            margin: 0 auto;
        }
        &--right {
            margin: 0 0 0 auto;
            text-align: right;
            align-items: flex-end;
        }
    }

    &-about {
        background-color: rgba(60, 61, 65, 0.3);
        backdrop-filter: blur(5px);
        display: flex;
        flex-direction: column;
        row-gap: 100px;
        margin-top: -200px;
        z-index: 1;
        position: relative;
        padding-top: 80px;
        padding-bottom: 80px;
        @include mq($until: md){
            margin-top: -100px;
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(60, 61, 65, 0.8);
            mix-blend-mode: multiply;
            width: 100%;
            height: 100%;
            z-index: -1;
        }    
        .top, .bottom {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 100px;
        }
        @include mq($until: xl){
            .top {
                grid-template-columns: auto 33%;
            }
            .bottom {
                grid-template-columns: 1fr;
            }
        }
        @include mq($until: sm){
            row-gap: 40px;
            .top {
                grid-template-columns: 1fr;
                row-gap: 40px;
                text-align: center;
            }
        }
        p {
            color: #fff;
            font-size: rem(17px);
            font-weight: 500;
            line-height: rem(30px);
        }
        h3 {
            color: #fff;
            font-size: rem(23px);
            font-weight: 700;
            margin-bottom: 40px;
            strong {
                color: $color-main;
                font-weight: 800;
                margin-left: 10px;
                border-left: 2px solid #fff;
                padding-left: 10px;
                display: inline-block;
            }
        }
        .content {
            h3 {
                margin: 0;
            }
        }
        .logo {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            @include mq($until: sm){
                order: -1;
                justify-content: center;
            }
        }
        .list {
            display: flex;
            flex-direction: column;
            justify-content: center;
            row-gap: 20px;
            &__single {
                border: 1px solid transparent;
                transition: ease 0.3s all;
                border-radius: 5px;
                padding: 20px;
                margin-left: -20px;
                margin-right: -20px;
                @include mq($until: sm){
                    text-align: center;
                    margin-left: 0;
                    margin-right: 0;
                    }
                &:hover {
                    background-color: rgba(255, 255, 255, 0.05);
                    border-color: rgba(255, 255, 255, 0.28);
                    h3 strong {
                        color: $color-extra;
                    }
                }
            }
            h3 {
                color: #fff;
                font-size: rem(23px);
                font-weight: 700;
                margin-bottom: 20px;
                strong {
                    transition: ease 0.3s all;
                    @include mq($until: sm){
                        display: block;
                        text-align: center;
                        border: 0;
                        padding: 0;
                        margin: 10px 0 0 0;
                    }
                }
            }
        }
        .map {
            max-height: unset;
            @include mq($until: xl){
                text-align: center;
                order: -1;
            }
            svg {
                width: 100%;
                height: auto;
                @include mq($until: xl){
                    max-width: 800px;
                }
                .dot ellipse {
                    transition: ease 0.3s all;
                    -webkit-animation: opacity1 10s ease-in-out infinite, scale 10s ease-in-out infinite;
                    animation: opacity1 10s ease-in-out infinite, scale 10s ease-in-out infinite;
                    opacity: 0;
                    animation-delay: 0s;
                }
                text {
                    transition: ease 0.3s all;
                    -webkit-animation: opacity2 10s ease-in-out infinite;
                    animation: opacity2 10s ease-in-out infinite;
                    opacity: 0;
                    animation-delay: 0s;
                }
            }
        }

    }
}

@keyframes opacity1 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes opacity2 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    20% {
        opacity: 1;
    }
    30% {
        opacity: 1;
    }
    80% {
        opacity: 1;
      }
      90% {
        opacity: 0;
      }
        100% {
      opacity: 0;
    }
  }
  
  @keyframes scale {
    0% {
      -webkit-transform: scale(1) translateY(0) translateX(0);
              transform: scale(1) translateY(0) translateX(0);
    }
    5% {
      -webkit-transform: scale(1.6) translateY(-6px) translateX(-4px);
              transform: scale(1.2) translateY(-6px) translateX(-4px);
    }
    10% {
      -webkit-transform: scale(1) translateY(0) translateX(0);
              transform: scale(1) translateY(0) translateX(0);
    }
    100% {
      -webkit-transform: scale(1) translateY(0) translateX(0);
              transform: scale(1) translateY(0) translateX(0);
    }
  }
  