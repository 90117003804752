.footer {
  position: relative;
  &-content {
    padding-top: 30px;
    padding-bottom: 30px;
    background: linear-gradient(45deg, #22242F 0%, rgba(38, 40, 52, 0.8) 50%, #464B5D 100%);
    backdrop-filter: blur(5px);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    @include mq($until: 1630px){
      flex-direction: column;
      position: relative;
    }
    h2 {
      color: #fff;
      font-weight: 400;
      font-size: rem(20px);
      padding-bottom: 20px;
      border-bottom: 1px solid #fff;
      margin-bottom: 20px;
    }
    .menu {
      .header__menuList {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 50px;
        row-gap: 5px;
        @include mq($until: 1630px){
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          column-gap: 20px;
        }
        @include mq($until: md){
          display: grid;
          grid-template-columns: auto 1fr;
          column-gap: 50px;
        }
        @include mq($until: xs){
          grid-template-columns: 1fr;
        }  
      }
      .header__menuItem {
        margin: 0;
      }
      .header__menuLink {
        color: #fff;
        font-weight: 300;
        font-size: rem(16px);
        padding: 0;
        &:active, &:hover {
          color: $color-main;
        }
      }
    }
    .contact {
      display: flex;
      align-items: center;
      column-gap: 50px;
      @include mq($until: 1630px){
        justify-content: space-between;
        column-gap: 20px;
      }
      @include mq($until: md){
        display: grid;
        grid-template-columns: auto 1fr;
        row-gap: 30px;
        column-gap: 50px;
      }
      @include mq($until: xs){
        grid-template-columns: 1fr;
      }  

      p, a {
        color: #fff;
        font-weight: 300;
        font-size: rem(16px);
        white-space: nowrap;
      }
      p {
        margin: 0;
        line-height: 30px;
      }
      a:hover {
        color: $color-main;
      }
      .links {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        column-gap: 10px;
        a {
          display: flex;
          align-items: center;
        }
        svg {
          fill: #fff;
          margin-right: 10px;
          width: 19px;
          height: auto;
        }
      }
    }
  }
  &-map {
    height: 90vh;
    position: relative;
    z-index: 0;
    @include mq($until: 1630px){
      height: 80vh;
    }
    @include mq($until: md){
      height: 50vh;
    }
  }
  &-small{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 20px;
      @include mq($until: xs){
        flex-direction: column;
        gap: 5px;
      }  
      span,a {
        color: #4E5360;
        font-size: rem(16px);
        font-weight: 300;
        &:hover {
          color: $color-main;
        }
      }
      &__copyright {
        color: #4E5360;
        font-size: rem(16px);
        font-weight: 300;
      }
      &__realization{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        span{
          transition: color .3s ease-in-out;
        }
        &:hover span{
          color: $color-main;
        }
        img{
          margin-left: 15px;
          height: auto;
        }
      }
  }
}
