.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 120px;
  column-gap: 20px;
  //border-bottom: solid 2px $color-main;
  background-color: #fff;
  &--absolute {
    position: absolute;
    z-index: 3;
    width: 100%;
    border-bottom: none;
    top: 0;
    .header__menuLink {
      //color: #fff;
    }
  }

  &--fixed {
    transition: background-color .3s ease-in-out;
    &.sticky {
      position: fixed;
      z-index: 3;
      width: 100%;
      background-color: #fff;
    }
  }

  &__logo{
    position: relative;
    z-index: 2;
    margin-top: 15px;
    margin-bottom: 15px;
    img {
      height: auto;
      @include mq($until: 1300px){
        max-width: 100px;
      }
    }
  }

  &__nav {
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    flex-wrap: wrap;
    &.contact {
      display: none;
      @include mq($until: md){
        display: block;
      }
      svg {
        fill: #fff;
        margin-right: 10px;
      }
    }
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    padding: 0;
    margin: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: #707070;
    padding: 15px 30px;
    font-size: rem(17px);
    font-weight: 500;
    @include mq($until: 1600px){
      padding: 15px 15px;
    }
    @include mq($until: 1300px){
      font-size: rem(16px);
    }
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: $color-main;
    }
  }
}