.features {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 100px;
    row-gap: 100px;
    @include mq($until: xl){
        column-gap: 50px;
        grid-template-columns: 1fr;
    }
    @include mq($until: md){
        padding-top: 50px;
        padding-bottom: 50px;
    }
    @include mq($until: sm){
        padding-top: 30px;
        padding-bottom: 40px;
    }
    @include mq($until: xs){
        padding-top: 20px;
        padding-bottom: 40px;
    }

    &__single {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: space-between;
        @include mq($until: xl){
            display: grid;
            column-gap: 50px;
            grid-template-columns: auto 33%;
            overflow: unset;
        }
        @include mq($until: md){
            grid-template-columns: 1fr;
            row-gap: 50px;
        }
        &:nth-child(2n-1) {
            .features__single-image {
                svg {
                    top: -240px;
                    bottom: unset;
                    right: unset;
                    left: -180px;
                    @include mq($until: xl){
                        bottom: unset;
                        right: unset;
                        left: -7vw;
                        top: -7vw;
                    }
                    @include mq($until: md){
                        right: -7vw;
                        left: unset;
                        top: -14vw;
                    }    
                    path {
                        fill: $color-main;
                    }
                }
            }
        }
        &:nth-child(2n) {
            @include mq($until: xl){
                grid-template-columns: 33% auto;
            }
            @include mq($until: md){
                grid-template-columns: 1fr;
            }    
            .features__single-title {
                margin-right: 0;
                margin-left: 50px;
                @include mq($until: xl){
                    margin-left: 0;
                }
                @include mq($until: sm){
                    margin-left: 30px;
                }
                @include mq($until: xs){
                    margin-left: 20px;
                }
            }
            .features__single-content {
                //padding-bottom: 50px;
                margin-right: 0;
                margin-left: 50px;
                @include mq($until: xl){
                    margin-left: 0;
                }
            }
            .features__single-image {
                order: -1;
                margin-top: 0;
                margin-bottom: 100px;      
                @include mq($until: md){
                    padding-left: 50px;
                    padding-right: 0;
                    margin-bottom: 0;      
                }
                @include mq($until: sm){
                    padding-left: 30px;
                    padding-right: 0;
                }
                @include mq($until: xs){
                    padding-left: 20px;
                    padding-right: 0;
                }    
                hr {
                    bottom: unset;
                    top: 100%;
                    right: unset;
                    left: 50px;
                }
            }
        }
        &-title {
            display: flex;
            margin-bottom: 20px;
            align-items: center;
            margin-right: 50px;
            @include mq($until: md){
                flex-direction: column;
                gap: 30px;
                margin-right: 30px;
            }
            @include mq($until: xs){
                margin-right: 20px;
            }
            svg {
                margin-right: 30px;
                width: 100px;
                height: auto;
                @include mq($until: xl){
                    width: 80px;
                }
                @include mq($until: md){
                    width: 60px;
                    margin-right: 0;
                }
            }
            h2 {
                font-size: rem(39px);
                font-weight: 800;
                margin: 0;
                @include mq($until: xl){
                    font-size: rem(34px);
                }
                @include mq($until: md){
                    font-size: rem(24px);
                }
            }
        }
        &-content {
            margin-right: 50px;
            @include mq($until: xl){
                margin-right: 0;
            }
            @include mq($until: md){
                text-align: center;
            }
            strong {
                color: $color-extra;
                font-size: rem(20px);
                font-weight: 700;
                max-width: 400px;
                display: block;
                @include mq($until: md){
                    max-width: 100%;
                } 
            }
            p {
                font-size: rem(17px);
                font-weight: 500;
                line-height: rem(30px);
            }
        }
        &-image {
            margin-top: 100px;
            position: relative;
            background: #fff;
            @include mq($until: xl){
                margin-top: 0;
            }
            @include mq($until: md){
                order:-1;
                padding-right: 50px;
            }
            @include mq($until: sm){
                padding-right: 30px;
            }
            @include mq($until: xs){
                padding-right: 20px;
            }
            img {
                width: 100%;
                height: auto;
                position: relative;
                z-index: 1;
            }
            svg {
                position: absolute;
                bottom: -220px;
                right: -130px;
                width: 315px;
                height: auto;
                @include mq($until: xl){
                    width: 15vw;
                    bottom: unset;
                    right: unset;
                    left: -5vw;
                    top: -15vw;
                }
                @include mq($until: md){
                    width: 20vw;
                    left: -10vw;
                    top: -20vw;
                }
                path {
                    fill: $color-extra;
                }
            }
        }
        .animate-border {
            hr {
                background-color: #53575E;
                position: absolute;
                width: 1px;
                height: 0%;
                right: 50px;
                top: 0;
                border: unset;
                margin:0;
                padding:0;
                transition: ease 2s all;
                @include mq($until: xl){
                    display: none;
                }
            }
            &.l hr {
                left: 50px;
                right: unset;
            }
            &.anmt hr {
                height: 100%;
            }
        }
    }
}
