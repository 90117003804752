.projects {
    h1 {
        padding: 0 8vw;
        text-align: center;
        margin-bottom: 30px;
        @include mq($until: md){
            font-size: rem(24px);
            padding: 0 50px;
        }
        @include mq($until: sm){
            padding: 0 30px;
        }
        @include mq($until: xs){
            padding: 0 20px;
        }
    }
    .subsite__content {
        & > p {
            padding: 0 8vw;
            text-align: center;
        }
        a {
            font-size: rem(22px);
            color: $paragraph-color;
            strong {
                color: $paragraph-color;
                font-weight: 700;
                text-decoration: underline;
            }
        }
    }
    .row {
        padding-top: 20px;
        padding-bottom: 20px;
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        -moz-column-gap: 100px;
        column-gap: 100px;
        row-gap: 100px;
        @include mq($until: xl){
            column-gap: 50px;
            row-gap: 50px;
            grid-template-columns: 1fr;
        }
        &:first-of-type {
            margin-top: 40px;
        }
        .col-lg-6 {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            justify-content: space-between;
            max-width: unset;
            padding: 0;
            @include mq($until: xl){
                &.col-right .txtimg {
                    grid-template-columns: auto 33%;
                }
                &.col-right .txtimg__image {
                    order: 2;
                }
            }
            @include mq($until: md){
                &.col-right .txtimg {
                    grid-template-columns: 1fr;
                    row-gap: 50px;
                }
                &.col-right .txtimg__image {
                    order: 0;
                    padding-left: 50px;
                    padding-right: 0;
                }
            }
            @include mq($until: sm){
                &.col-right .txtimg__image {
                    padding-left: 30px;
                }
            }
            @include mq($until: xs){
                &.col-right .txtimg__image {
                    padding-left: 20px;
                }
            }
        }
        .txtimg {
            @include mq($until: xl){
                display: grid;
                column-gap: 50px;
                grid-template-columns: 33% auto;
                overflow: unset;
            }
            @include mq($until: md){
                grid-template-columns: 1fr;
                row-gap: 50px;
            }
            &__content {
                padding-left: 8vw;
                padding-right: 8vw;    
                @include mq($until: md){
                    padding-left: 50px;
                    padding-right: 50px;
                    text-align: center;  
                }
    
                h3 {
                    font-size: rem(24px);
                    font-weight: 700;
                    margin-bottom: 40px;
                }
                p {
                    font-size: 17px;
                    color: #464B5D;
                    strong {
                        display: inline;
                        font-size: 17px;
                        color: #464B5D;
                    }
                }
                img {
                    margin-bottom: 40px;
                    @include mq($until: md){
                        margin: 0 auto;
                    }
                }
                a {
                    margin-top: 40px;
                    display: inline-block;
                    &.button {
                        background-color: unset;
                        color: $paragraph-color;
                        padding: 0;
                        border-radius: 0;
                        font-weight: 700;
                        text-decoration: underline;
                    }
                }                
            }
            @include mq($until: md){
                &__image {
                    padding-right: 50px;
                }
            }
            @include mq($until: sm){
                &__image {
                    padding-right: 30px;
                }
            }
            @include mq($until: xs){
                &__image {
                    padding-right: 20px;
                }
            }
        }
    }
}
