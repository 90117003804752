.subsite {
    &__hero {
        min-height: 70vh;
        background-position: center;
        background-size: cover;
        @include mq($until: md){
            min-height: unset;
            aspect-ratio: 16/9;
        }
    }
    &__content {
        padding-top: 80px;
        padding-bottom: 100px;
        h1 {
            margin-bottom: 80px;
        }
        strong {
            color: $color-extra;
        }
        a strong {
            color: $color-main;
        }
        .fr-fic {
            margin: 0 auto;
        }
        .fr-dib {
            display: block;
        }
        .row.full {
            margin-left: -8vw;
            margin-right: -8vw;
            padding-top: 0px;
            padding-bottom: 0px;
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            -moz-column-gap: 100px;
            column-gap: 100px;
            row-gap: 100px;
            @include mq($until: xl){
                margin-left: -100px;
                margin-right: -100px;
                grid-template-columns: 1fr;
            }
            @include mq($until: lg){
                margin-left: -50px;
                margin-right: -50px;    
            }
            @include mq($until: sm){
                margin-left: -30px;
                margin-right: -30px;    
            }
            @include mq($until: xs){
                margin-left: -20px;
                margin-right: -20px;    
            }
            .col-lg-6 {
                display: flex;
                width: 100%;
                flex: 1;
                max-width: 100%;
                padding: 0;
                margin: 0;
                &:last-of-type .article {
                    flex-direction: column;
                    @include mq($until: xl){
                        grid-template-columns: auto 33%;
                    }
                    @include mq($until: md){
                        grid-template-columns: 1fr;
                    }
                    .article__image {
                        margin-top: 0;
                        margin-bottom: 100px;
                        @include mq($until: xl){
                            margin-bottom: 0;
                            order: 1;
                        }
                        @include mq($until: md){
                            order: 0;
                            padding-right: 0;
                            padding-left: 50px;
                            margin-bottom: 50px;
                        }
                        @include mq($until: sm){
                            padding-right: 0;
                            padding-left: 30px;
                        }
                        @include mq($until: xs){
                            padding-right: 0;
                            padding-left: 20px;
                        }
                    }
                }
            }
            .col-lg-6 .article {
                position: relative;
                z-index: 1;
                display: flex;
                flex-direction: column-reverse;
                overflow: hidden;
                justify-content: space-between;
                @include mq($until: xl){
                    margin-bottom: 0;
                    display: grid;
                    -moz-column-gap: 50px;
                    column-gap: 50px;
                    grid-template-columns: 33% auto;
                }
                @include mq($until: md){
                    grid-template-columns: 1fr;
                }
                .article__image {
                    margin-top: 100px;
                    position: relative;
                    background: #fff;
                    @include mq($until: xl){
                        margin-top: 0;
                    }
                    @include mq($until: md){
                        margin-bottom: 50px;
                        padding-right: 50px;
                    }
                    @include mq($until: sm){
                        padding-right: 30px;
                    }
                    @include mq($until: xs){
                        padding-right: 20px;
                    }
                    img {
                        width: 100%;
                        height: auto;
                        position: relative;
                        z-index: 1;
                    }
                }
                .article__text {
                    margin-left: 0;
                    margin-right: 50px;
                    padding-left: 8vw;
                    padding-right: 8vw;
                    width: 100%;
                    @include mq($until: xl){
                        padding-left: 100px;
                        padding-right: 100px;
                    }
                    @include mq($until: lg){
                        padding-left: 50px;
                        padding-right: 50px;
                    }
                    @include mq($until: md){
                        text-align: center;
                    }
                    @include mq($until: sm){
                        padding-left: 30px;
                        padding-right: 30px;
                    }
                    @include mq($until: xs){
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                    h3 {
                        font-size: rem(39px);
                        font-weight: 800;
                        margin-bottom: 40px;
                        @include mq($until: xl){
                            font-size: rem(34px);
                        }
                        @include mq($until: md){
                            font-size: rem(24px);
                        }
                    }
                }
            }
            .article__image, .article__slider {
                height: auto;
                width: 100%;
            }
        }
    }
    .footer-content {
        position: relative;
    }
}
